import queryClient from '#/api/query-client';
import { useDelete, useGet, usePost, usePut } from '#/api/reactQuery';
import { IChildren } from '#/types/user';

const baseURL = '/child';

export const ChildrenEndpoints = {
  getChildren: () => `${baseURL}/list/`,
  getChild: (id: number | string | undefined) => `${baseURL}/${id}/`,
  addChild: () => `${baseURL}/add/`,
  updateChild: (id: number | string | undefined) => `${baseURL}/${id}/update/`,
  deleteChild: (id: number | string | undefined) => `${baseURL}/${id}/delete/`,
  deleteAllChildren: () => `${baseURL}/delete/all/`,
  deleteMultipleChildren: () => `${baseURL}/delete/multiple/`,
};

export const invalidateChildrenQueries = {
  getChildren: () => {
    queryClient
      .invalidateQueries(ChildrenEndpoints.getChildren())
      .then((r) => r);
  },
  getChild: (id: number | string | undefined) => {
    queryClient
      .invalidateQueries(ChildrenEndpoints.getChild(id))
      .then((r) => r);
  },
};

export const useGetChildren = () =>
  useGet<IChildren>(ChildrenEndpoints.getChildren());

export const useGetChild = (id: number | string | undefined) =>
  useGet(ChildrenEndpoints.getChild(id));

export const useAddChild = () => usePost(ChildrenEndpoints.addChild());
export const useUpdateChild = (id: number | string | undefined) =>
  usePut(ChildrenEndpoints.updateChild(id));

export const useDeleteChild = (id: number | string | undefined) =>
  useDelete(ChildrenEndpoints.deleteChild(id));

export const useDeleteAllChildren = () =>
  useDelete(ChildrenEndpoints.deleteAllChildren());

export const useDeleteMultipleChildren = () =>
  usePost(ChildrenEndpoints.deleteMultipleChildren());
